import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';

export class HPEBulletColorMixin extends ESLMixinElement {
  static override is = 'hpe-bullet-color';

  protected override connectedCallback(): void {
    this.$host.style.setProperty('--bullet-color', this.$$attr(HPEBulletColorMixin.is));
  }
}

